/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { EqualHeightElement } from 'react-equal-height';
import { BsCheck2, BsX } from 'react-icons/bs';

import AccessoriesTooltipComponent from 'Component/AccessoriesTooltip';
import AddToCart from 'Component/AddToCart';
import Field from 'Component/Field';
import GetSampleButtonComponent from 'Component/GetSampleButton';
import Html from 'Component/Html';
import ImageWebp from 'Component/ImageWebp';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import {
    NO_MAP_RESTRICTIONS, PRODUCT_ACTIONS_WISHLIST_BUTTON, VERIFIED_CUSTOMERS, WHOLESALE
} from 'Component/ProductActions/ProductActions.config';
import { ATTRIBUTE_WISHLIST_BUTTON } from 'Component/ProductAttributeValue/ProductAttributeValue.config';
import ProductPrice from 'Component/ProductPrice';
import ProductSwatchPopupComponent from 'Component/ProductSwatchPopup';
import { PDP_PLP } from 'Component/ProductSwatchPopup/ProductSwatchPopup.config';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import TooltipComponent from 'Component/Tooltip';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { NUMBER_OF_CHARACTERS_TO_SHOW } from 'Route/ProductPage/ProductPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import { CROSS_SELL, RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';
import {
    BUNDLE, CONFIGURABLE, GROUPED, SIMPLE
} from 'Util/Product';

import {
    ACCESSORIES_ATTRIBUTE_SET_ID,
    FORTY_PERCENT,
    RUG_ATTRIBUTE_ID,
    UPSELL,
    validOptionTypes
} from './ProductCard.config';

/** @namespace ZnetPwa/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...this.propTypes,
        isDiscontinued: PropTypes.bool.isRequired,
        customerGroupName: PropTypes.string.isRequired,
        changeToSwatchImage: PropTypes.func.isRequired,
        changeToProductImage: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        onPopUpOutsideClick: PropTypes.func.isRequired,
        toggleProductPopUp: PropTypes.func.isRequired,
        isSwatchPopupOpen: PropTypes.bool.isRequired,
        isRequiredAccessory: PropTypes.bool.isRequired,
        isEqualHeight: PropTypes.bool.isRequired,
        isSearchSpringSearch: PropTypes.bool.isRequired,
        isMolding: PropTypes.bool,
        isSelected: PropTypes.bool,
        isConfigurableSwatchPopUp: PropTypes.bool.isRequired,
        configurableMinPrice: PropTypes.number,
        configurableMaxPrice: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        popUpProducts: PropTypes.array.isRequired,
        onQuickViewClick: PropTypes.func.isRequired,
        onConfirmClick: PropTypes.func.isRequired,
        linkType: PropTypes.string,
        device: PropTypes.bool.isRequired,
        isCartPopup: PropTypes.bool,
        toggleDisableSampleButton: PropTypes.func.isRequired,
        isSamplesDisabled: PropTypes.bool.isRequired,
        toggleCustomQtyInput: PropTypes.func.isRequired,
        isCustomQtyInputVisible: PropTypes.bool.isRequired,
        isConfirmQtyVisible: PropTypes.bool.isRequired,
        isAddingAccessory: PropTypes.bool.isRequired,
        isScroll: PropTypes.bool.isRequired,
        qtyInputRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        isPreview: PropTypes.bool.isRequired,
        recordSearchSpringProductClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isSelected: false,
        linkType: '',
        isCartPopup: false,
        configurableMinPrice: 0,
        configurableMaxPrice: 0
    };

    renderHeader() {
        const {
            isEqualHeight,
            product: {
                dynamicAttributes,
                dynamicAttributes: {
                    collection_name,
                    plank_width
                } = {}
            },
            linkType
        } = this.props;

        const plankWidth = plank_width ? `${ plank_width } in.` : '';

        if (dynamicAttributes === undefined || dynamicAttributes === {}) {
            return null;
        }

        if (linkType === CROSS_SELL) {
            return null;
        }

        if (!isEqualHeight) {
            return (
                <>
                    <div
                      block="ProductCard"
                      elem="Header-CollectionName"
                    >
                        <Html content={ collection_name || '' } />
                    </div>
                  <div
                    block="ProductCard"
                    elem="Header-PlankWidth"
                  >
                      <Html content={ plankWidth || '' } />
                  </div>
                </>
            );
        }

        // Either pass prop value or '' because prop value can be null, which will throw an error
        return (
            <>
                <EqualHeightElement name="CollectionName">
                <div
                  block="ProductCard"
                  elem="Header-CollectionName"
                >
                    <Html content={ collection_name || '' } />
                </div>
                </EqualHeightElement>
              <div
                block="ProductCard"
                elem="Header-PlankWidth"
              >
                  <Html content={ plankWidth || '' } />
              </div>
            </>
        );
    }

    renderIsRequiredHeader() {
        const { isRequiredAccessory } = this.props;

        if (isRequiredAccessory) {
            return (
                <h2
                  block="ProductCard"
                  elem="Header-RequiredAccessory"
                >
                    { __('Required Accessory') }
                </h2>
            );
        }

        return null;
    }

    renderSubHeader() {
        const { product: { dynamicAttributes, sku }, isSearchSpringSearch, isEqualHeight } = this.props;

        if (dynamicAttributes === undefined) {
            return null;
        }

        const {
            product: {
                dynamicAttributes: {
                    flooring_type
                }
            }
        } = this.props;

        // Either pass prop value or '' because prop value can be null, which will throw an error
        const flooringType = flooring_type && flooring_type !== 'false' ? flooring_type : '';

        if (isSearchSpringSearch) {
            if (!isEqualHeight) {
                return (
                    <>
                    <div
                      block="ProductCard"
                      elem="Sku"
                    >
                            <Html content={ sku } />
                    </div>
                    <div
                      block="ProductCard"
                      elem="FlooringType"
                    >
                            <Html content={ flooringType } />
                    </div>
                    </>
                );
            }

            return (
                <>
                <EqualHeightElement name="SKU">
                    <div
                      block="ProductCard"
                      elem="Sku"
                    >
                            <Html content={ sku } />
                    </div>
                </EqualHeightElement>
                <EqualHeightElement name="FlooringType">
                    <div
                      block="ProductCard"
                      elem="FlooringType"
                    >
                            <Html content={ flooringType } />
                    </div>
                </EqualHeightElement>
                </>
            );
        }

        return (
            <div
              block="ProductCard"
              elem="FlooringType"
            >
                    <Html content={ flooringType } />
            </div>
        );
    }

    renderMainDetails() {
        const { layout } = this.props;

        if (layout === GRID_LAYOUT) {
            return this.renderProductName();
        }

        return this.renderCardLinkWrapper(
            this.renderProductName()
        );
    }

    renderProductName() {
        const { product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }

    renderPicture(mix = {}) {
        const {
            product: {
                id,
                name,
                sku
            },
            productOrVariant: {
                attribute_set_id,
                type_id,
                variants
            },
            thumbnail,
            linkType,
            isCartPopup
        } = this.props;
        const alt = `${ sku } - ${ name }`;
        const isAccessory = attribute_set_id === ACCESSORIES_ATTRIBUTE_SET_ID;
        let thumbnailToUse = thumbnail;
        let width = '';
        let height = '';

        if (isAccessory) {
            width = FORTY_PERCENT;
            height = FORTY_PERCENT;
        }

        // If parent rug doesn't have an image, use the image of the first child
        // Ref. https://sepoy.atlassian.net/browse/ZFR-1450
        if (attribute_set_id?.toString() === RUG_ATTRIBUTE_ID && type_id === CONFIGURABLE) {
            if (thumbnail?.includes('placeholder')) {
                thumbnailToUse = variants[0]?.image?.url;
            }
        }

        return (
            <ImageWebp
              width={ width }
              height={ height }
              imageRef={ this.imageRef }
              src={ thumbnailToUse }
              style={ isCartPopup || linkType === UPSELL ? {} : { objectFit: 'cover' } }
              alt={ alt }
              ratio="custom"
              mix={ {
                  block: 'ProductCard',
                  elem: linkType !== '' ? linkType : 'Picture',
                  mix,
                  mods: { isCartPopup }
              } }
              isPlaceholder={ !id }
            />
        );
    }

    renderQuantityInput() {
        const {
            linkType,
            quantity,
            maxQuantity,
            setQuantity,
            product: { type_id }
        } = this.props;

        if (type_id === GROUPED) {
            return null;
        }

        const defaultQuantity = !quantity || quantity === 0 ? 1 : quantity;
        const qty = linkType === UPSELL ? (
            <span block="ProductCard" elem="QtyWord">
                { __('QTY:') }
            </span>
        ) : '';

        return (
            <div block="ProductCard" elem="QtyWrapper">
            { qty }
            <Field
              id="item_qty"
              name="item_qty"
              type="number"
              value={ defaultQuantity }
              max={ maxQuantity }
              min={ 1 }
              mix={ { block: 'ProductCard', elem: 'Qty' } }
              onChange={ setQuantity }
            />
            <br />
            </div>
        );
    }

    renderDescription() {
        const {
            productOrVariant: {
                sku
            } = {},
            showMore,
            upSellDescription: {
                items
            }
        } = this.props;

        const currentUpSellItem = items !== undefined ? items.filter((item) => item.sku === sku)[0] : '';
        const { description: { html: description } = {} } = currentUpSellItem;

        if (description !== undefined) {
            const contentToShow = description.substr(0, NUMBER_OF_CHARACTERS_TO_SHOW);
            const expandedContent = description.substr(
                NUMBER_OF_CHARACTERS_TO_SHOW, description.length - NUMBER_OF_CHARACTERS_TO_SHOW
            );
            const moreText = description.length > NUMBER_OF_CHARACTERS_TO_SHOW ? __('... more >>>') : '';
            const lessText = description.length > NUMBER_OF_CHARACTERS_TO_SHOW ? __('<<less') : '';

            return (
                <section
                  block="ProductCard"
                  elem="Section"
                  mods={ { type: 'Description' } }
                >
                    <p
                      block="ProductCard"
                      elem="Description"
                    >
                        <span>
                            { contentToShow }
                            <span
                              block="ProductCard"
                              elem="Description"
                              mods={ { type: 'more' } }
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={ (e) => showMore(e) }
                              // eslint-disable-next-line react/jsx-no-bind
                              onKeyDown={ (e) => showMore(e) }
                              role="button"
                              tabIndex="-1"
                            >
                                { moreText }
                            </span>
                            <span
                              block="ProductCard"
                              elem="Description"
                              mods={ { type: 'expanded_content' } }
                            >
                                { expandedContent }
                                &nbsp;
                                <span
                                  block="ProductCard"
                                  elem="Description"
                                  mods={ { type: 'less' } }
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onClick={ (e) => showMore(e) }
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onKeyDown={ (e) => showMore(e) }
                                  role="button"
                                  tabIndex="-1"
                                >
                                    { lessText }
                                </span>
                            </span>
                        </span>
                    </p>
                </section>
            );
        }

        return null;
    }

    renderCustomAddToCart() {
        const {
            product: {
                id,
                stock_item: {
                    max_sales_qty
                } = {}
            } = {},
            onConfirmQtyChange,
            accessoryQuantity,
            isConfirmQtyVisible,
            hideConfirmQty,
            onConfirmClick,
            isAddingAccessory,
            isCustomQtyInputVisible,
            qtyInputRef
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="QuantityConfirmWrapper"
              mods={ { isCustomQtyInputVisible } }
            >
              <div
                block="ProductCard"
                elem="Tooltip"
                mods={ { isVisible: isConfirmQtyVisible } }
              >
                  <span
                    block="ProductCard"
                    elem="TooltipText"
                  >
                      { __('Click to confirm this amount') }
                  </span>
              </div>
              <div
                block="ProductCard"
                elem="QuantityWrapper"
              >
              <Loader isLoading={ isAddingAccessory } />
              <Field
                id={ `AddToCartQty-custom-${ id }` }
                name="AddToCartQty"
                type="number"
                formRef={ qtyInputRef }
                min={ 0 }
                max={ max_sales_qty }
                mix={ { block: 'ProductCard', elem: 'AddToCartQty', mods: { isConfirm: isConfirmQtyVisible } } }
                value={ accessoryQuantity }
                allowEmptyValue
                onChange={ onConfirmQtyChange }
              />
              <BsX
                block="ProductCard"
                elem="CancelButton"
                mods={ { isVisible: isConfirmQtyVisible } }
                onClick={ hideConfirmQty }
              />
              <BsCheck2
                block="ProductCard"
                elem="ConfirmButton"
                mods={ { isVisible: isConfirmQtyVisible } }
                onClick={ onConfirmClick }
              />
              </div>
            </div>
        );
    }

    renderAccessoryAddToCart() {
        const {
            product: {
                id,
                stock_item: {
                    max_sales_qty
                } = {}
            } = {},
            onAccessoryAddToCart,
            accessoryQuantity,
            toggleCustomQtyInput,
            isAddingAccessory,
            isCustomQtyInputVisible,
            isCartPopup
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="AccessoryAddToCartWrapper"
              mods={ { isAddToCartVisible: !isCustomQtyInputVisible, isCartPopup } }
            >
                <Loader isLoading={ isAddingAccessory } />
                <Field
                  id={ `AddToCartQty-${ id }` }
                  name="AddToCartQty"
                  type="number"
                  isControlled
                  min={ 0 }
                  max={ max_sales_qty }
                  mix={ { block: 'ProductCard', elem: 'AddToCartQty' } }
                  value={ accessoryQuantity }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (value) => onAccessoryAddToCart(value) }
                  onClick={ toggleCustomQtyInput }
                  isLoading={ isAddingAccessory }
                />
            </div>
        );
    }

    renderAccessoryQuantityButton() {
        return (
            <>
                { this.renderCustomAddToCart() }
                { this.renderAccessoryAddToCart() }
            </>
        );
    }

    renderAddToCart() {
        const {
            linkType,
            quantity,
            product,
            product: {
                type_id
            },
            id,
            activeQtyInput,
            onAccessoryAddToCartClick,
            accessoryQuantity
        } = this.props;
        const configurableVariantIndex = -1;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        if (type_id !== 'simple') {
            return this.renderCardLinkWrapper(
                <button block="Button">{ __('Add To Cart') }</button>
            );
        }

        if (linkType === CROSS_SELL) {
            if (accessoryQuantity === 0 || id !== activeQtyInput) {
                return (
                    <button
                      block="Button ProductCard"
                      elem="AccessoryAddToCartButton"
                          // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => onAccessoryAddToCartClick(id, accessoryQuantity) }
                    >
                            { __('Add to Cart') }
                    </button>
                );
            }

            return this.renderAccessoryQuantityButton();
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductCard', elem: 'AddToCart', mods: { linkType } } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
            />
        );
    }

    renderProductWishlistButton() {
        const {
            product,
            quantity,
            configurableVariantIndex,
            onProductValidationError,
            productOptionsData,
            groupedProductQuantity,
            isWishlistEnabled
        } = this.props;

        if (!isWishlistEnabled) {
            return null;
        }

        return (
            <ProductWishlistButton
              product={ product }
              quantity={ quantity }
              configurableVariantIndex={ configurableVariantIndex }
              onProductValidationError={ onProductValidationError }
              productOptionsData={ productOptionsData }
              groupedProductQuantity={ groupedProductQuantity }
              type={ ATTRIBUTE_WISHLIST_BUTTON }
            />
        );
    }

    renderAccessoriesProductName() {
        const {
            productOrVariant: {
                name
            }
        } = this.props;

        return (
            <span block="ProductCard" elem="AccessoriesProductName">
                { ` ${ name }` }
            </span>
        );
    }

    renderAccessoriesProductSKU() {
        const {
            product: {
                sku
            }
        } = this.props;

        return (
            <span block="ProductCard" elem="AccessoriesProductSKU">
                { `${ sku }` }
            </span>
        );
    }

    renderCardWrapperProcessor(renderCardWrapper, product, content) {
        const {
            recordSearchSpringProductClick
        } = this.props;

        if (recordSearchSpringProductClick !== null) {
            return (
                renderCardWrapper(product, content, recordSearchSpringProductClick)
            );
        }

        return (
            renderCardWrapper(product, content)
        );
    }

    renderSizeSubHeader() {
        const {
            isScroll,
            product: {
                attributes: {
                    accessory_size: {
                        attribute_label,
                        attribute_value
                    } = {}
                } = {},
                description: {
                    html
                } = {}
            } = {},
            tooltipOverlayRef
        } = this.props;

        if (!attribute_value) {
            return null;
        }

        if (tooltipOverlayRef?.current) {
            return (
                <div
                  block="ProductCard"
                  elem="AccessorySize"
                >
                    { `${ attribute_label }: ${ attribute_value }` }
                    { (html && (
                        <AccessoriesTooltipComponent
                          mix={ { block: 'ProductCard', elem: 'AccessorySizeTooltip' } }
                          tooltipText={ html }
                          blockRef={ tooltipOverlayRef }
                          isScroll={ isScroll }
                        />
                    )
                    ) }
                </div>
            );
        }

        return (
            <div
              block="ProductCard"
              elem="AccessorySize"
            >
                { `${ attribute_label }: ${ attribute_value }` }
                { (html && (
                    <TooltipComponent
                      mix={ { block: 'ProductCard', elem: 'AccessorySizeTooltip' } }
                      tooltipText={ html }
                    />
                )
                ) }
            </div>
        );
    }

    renderDiscontinuedBanner() {
        const {
            isDiscontinued
        } = this.props;

        if (isDiscontinued) {
            return (
                <img
                  block="ProductCard"
                  elem="DiscontinuedBanner"
                  src="/media/discontinued_product_banner/discontinued_banner.png"
                  alt="Discontinued Banner"
                  loading="lazy"
                />
            );
        }

        return null;
    }

    renderCardContent() {
        const {
            renderContent,
            changeToSwatchImage,
            changeToProductImage,
            linkType,
            showOnlyIfLoaded,
            productOrVariant: {
                sku,
                name,
                attribute_set_id,
                attributes: {
                    rug_color: {
                        attribute_value,
                        attribute_options
                    } = {},
                    rug_sizes: {
                        attribute_value: rug_sizes_attribute_value,
                        attribute_options: rug_sizes_attribute_options
                    } = {}
                } = {},
                dynamicAttributes: {
                    brand,
                    collection_name,
                    collection_color
                } = {},
                type_id
            },
            renderCardWrapper,
            wrapperProduct,
            isCartPopup
        } = this.props;
        const isAccessory = attribute_set_id?.toString() === ACCESSORIES_ATTRIBUTE_SET_ID;
        const isRug = attribute_set_id?.toString() === RUG_ATTRIBUTE_ID;
        let colorToUse = '';
        let rugSize = '';

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (linkType === RELATED || linkType === UPSELL) {
            const rugOrFlooringColor = isRug
                ? ` - ${ attribute_options[attribute_value]?.label }`
                : ` - ${ collection_color}`;

            colorToUse = isAccessory || type_id === CONFIGURABLE ? '' : rugOrFlooringColor;

            rugSize = isRug && type_id === SIMPLE
                ? (
                <span block="ProductCard" elem="RugSize">
                    { `Size: ${ rug_sizes_attribute_options[rug_sizes_attribute_value]?.label }` }
                </span>
                ) : null;
        }

        if (linkType === RELATED) {
            return (
                this.renderCardLinkWrapper(
                    showOnlyIfLoaded(
                        sku && name,
                        (
                            <div block="ProductCard" elem="RelatedWrapper">
                                <div block="ProductCard" elem="PictureWrapper" mods={ { linkType } }>
                                    { this.renderPicture() }
                                </div>
                                <span block="ProductCard" elem="RelatedBrandName">
                                    { brand }
                                </span>
                                <span block="ProductCard" elem="RelatedCollectionNameColor">
                                    { `${ collection_name }${ colorToUse }` }
                                </span>
                                { rugSize }
                                <div block="ProductCard" elem="RelatedSku">
                                    <span block="ProductCard" elem="Sku">
                                        { __('SKU:') }
                                        &nbsp;
                                    </span>
                                    <span block="ProductCard" elem="Sku">
                                        { ` ${ sku }` }
                                    </span>
                                </div>
                                { this.renderProductPrice() }
                            </div>
                        ),
                        <TextPlaceholder />
                    )
                )
            );
        }

        if (linkType === UPSELL) {
            if (isAccessory) {
                return (
                    this.renderCardLinkWrapper(
                        showOnlyIfLoaded(
                            sku && name,
                            (
                                <div block="ProductCard" elem="UpsellWrapper">
                                    <div block="ProductCard" elem="PictureWrapper" mods={ { linkType } }>
                                        { this.renderPicture() }
                                    </div>
                                    <span block="ProductCard" elem="UpsellBrandName">
                                        { name }
                                    </span>
                                    <div block="ProductCard" elem="UpsellSku">
                                        <span block="ProductCard" elem="Sku">
                                            { __('SKU:') }
                                            &nbsp;
                                        </span>
                                        <span block="ProductCard" elem="Sku">
                                            { ` ${ sku }` }
                                        </span>
                                    </div>
                                    { this.renderProductPrice() }
                                </div>
                            ),
                            <TextPlaceholder />
                        )
                    )
                );
            }

            return (
                this.renderCardLinkWrapper(
                    showOnlyIfLoaded(
                        sku && name,
                        (
                            <div block="ProductCard" elem="UpsellWrapper">
                                <div block="ProductCard" elem="PictureWrapper" mods={ { linkType } }>
                                    { this.renderPicture() }
                                </div>
                                <span block="ProductCard" elem="UpsellBrandName">
                                    { brand }
                                </span>
                                <span block="ProductCard" elem="UpsellCollectionNameColor">
                                    { `${ collection_name }${ colorToUse }` }
                                </span>
                                { rugSize }
                                <div block="ProductCard" elem="UpsellSku">
                                    <span block="ProductCard" elem="Sku">
                                        { __('SKU:') }
                                        &nbsp;
                                    </span>
                                    <span block="ProductCard" elem="Sku">
                                        { ` ${ sku }` }
                                    </span>
                                </div>
                                { this.renderProductPrice() }
                            </div>
                        ),
                        <TextPlaceholder />
                    )
                )
            );
        }

        if (linkType === CROSS_SELL) {
            if (isCartPopup) {
                return (
                    <>
                        <EqualHeightElement name="Product">
                            { this.renderCardLinkWrapper((
                                <>
                                    <div block="ProductCard" elem="Header">
                                        { this.renderHeader() }
                                    </div>
                                    <div block="ProductCard" elem="Figure">
                                        { this.renderPicture() }
                                    </div>
                                    <div
                                      block="ProductCard"
                                      elem="Details"
                                      mods={ { isCartPopup } }
                                    >
                                        { this.renderAccessoriesProductName() }
                                        { this.renderSizeSubHeader() }
                                        { this.renderAccessoriesProductSKU() }
                                    </div>
                                </>
                            )) }
                        </EqualHeightElement>
                        <EqualHeightElement name="Content">
                            <div
                              block="ProductCard"
                              elem="Content"
                              mods={ { isCrossSell: true, isCartPopup } }
                            >
                                { this.renderAdditionalProductDetails() }
                                { this.renderProductPrice() }
                                { this.renderAddToCart() }
                            </div>
                        </EqualHeightElement>
                    </>
                );
            }

            return (
                <>
                    { this.renderCardLinkWrapper((
                        <>
                            <div block="ProductCard" elem="Header">
                                { this.renderHeader() }
                            </div>
                            <div block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </div>
                            <div
                              block="ProductCard"
                              elem="Details"
                              mods={ { isCartPopup } }
                            >
                                { this.renderSizeSubHeader() }
                                { this.renderAccessoriesProductName() }
                                { this.renderAccessoriesProductSKU() }
                            </div>
                        </>
                    )) }
                    <div
                      block="ProductCard"
                      elem="Content"
                      mods={ { isCrossSell: true, isCartPopup } }
                    >
                        { this.renderAdditionalProductDetails() }
                        { this.renderProductPrice() }
                        { this.renderAddToCart() }
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                  block="ProductCard"
                  elem="FigureReview"
                  mods={ { isAccessory } }
                >
                    <figure
                      block="ProductCard"
                      elem="Figure"
                      onMouseOver={ changeToSwatchImage }
                      onFocus={ changeToSwatchImage }
                      onMouseOut={ changeToProductImage }
                      onBlur={ changeToProductImage }
                    >
                        { this.renderCardWrapperProcessor(renderCardWrapper, wrapperProduct, (
                            this.renderPicture()
                        )) }
                        { this.renderDiscontinuedBanner() }
                    </figure>
                    { this.renderVisualConfigurableOptions() }
                    { this.renderReviews() }
                </div>
                <div block="ProductCard" elem="Content">
                    { this.renderCardWrapperProcessor(renderCardWrapper, wrapperProduct,
                        <div block="ProductCard" elem="Information" mods={ { block: true } }>
                            <EqualHeightElement name="ProductCardInformation">
                                { this.renderFullProductInformation() }
                            </EqualHeightElement>
                        </div>) }
                    { this.renderAdditionalProductDetails() }
                    { this.renderProductPrice() }
                    { this.renderSampleAndWishlist() }
                    { this.renderSwatchPopup() }
                </div>
            </>
        );
    }

    renderSwatchPopup() {
        const {
            onPopUpOutsideClick,
            popUpProducts,
            isSwatchPopupOpen,
            isConfigurableSwatchPopUp,
            product: {
                sku: activeSku
            }
        } = this.props;

        if (!popUpProducts?.length) {
            return null;
        }

        // If there was no configurable option with color, don't render popup
        if (isConfigurableSwatchPopUp && popUpProducts[0]?.attribute === '') {
            return null;
        }

        return (
            <ProductSwatchPopupComponent
              activeSku={ activeSku }
              popUpType={ isConfigurableSwatchPopUp ? CONFIGURABLE : PDP_PLP }
              isConfigurableSwatchPopUpPLP={ isConfigurableSwatchPopUp }
              isOpen={ isSwatchPopupOpen }
              onPopUpOutsideClick={ onPopUpOutsideClick }
              products={ popUpProducts }
            />
        );
    }

    renderGetSample() {
        const {
            toggleDisableSampleButton,
            isSamplesDisabled,
            isRemovingProducts,
            isSampleInCart,
            removeSample,
            sample,
            product: {
                attribute_set_id
            },
            configurableProductsSwatches: {
                products = []
            } = {}
        } = this.props;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        if (attribute_set_id === ACCESSORIES_ATTRIBUTE_SET_ID) {
            return null;
        }

        if (sample) {
            return (
                <GetSampleButtonComponent
                  isConfigurable={ products?.length > 0 }
                  isRemovingProducts={ isRemovingProducts }
                  isSampleInCart={ isSampleInCart }
                  toggleDisableSampleButton={ toggleDisableSampleButton }
                  isSamplesDisabled={ isSamplesDisabled }
                  removeSample={ removeSample }
                  sample={ sample }
                  groupedProductQuantity={ groupedProductQuantity }
                  productOptionsData={ productOptionsData }
                />
            );
        }

        return null;
    }

    renderSampleAndWishlist() {
        return (
            <div block="ProductCard" elem="SampleAndWishlistWrapper">
                { this.renderGetSample() }
                { this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderConfigurableProductInformation() {
        const {
            isEqualHeight,
            popUpProducts,
            product: {
                sku,
                dynamicAttributes: {
                    collection_name = '',
                    brand = ''
                },
                rugAttributes: {
                    parent_rug_color = ''
                }
            },
            isConfigurableSwatchPopUp
        } = this.props;
        let productColor = popUpProducts[0]?.attribute;

        if (popUpProducts?.length > 1) {
            if (isConfigurableSwatchPopUp) {
                productColor = parent_rug_color !== '' ? parent_rug_color : __('Multiple sizes');
            } else {
                productColor = __('Multiple colors');
            }
        }
        // Replacing escaped quote in SS
        if (!isEqualHeight) {
            return (
                <>
                    <div
                      block="ProductCard"
                      elem="Information-CollectionNameSkuWrapper"
                    >
                        <div
                          block="ProductCard"
                          elem="Information-CollectionName"
                        >
                          { productColor }
                        </div>
                        <span block="ProductCard" elem="Information-Sku">
                            { sku }
                        </span>
                    </div>
                    <div
                      block="ProductCard"
                      elem="Information-ProductNameString"
                    >
                        { brand }
                    </div>
                    <div
                      block="ProductCard"
                      elem="Information-ProductNameString-Collection"
                    >
                        { collection_name }
                    </div>
                </>
            );
        }

        // Replacing escaped quote in SS
        return (
            <EqualHeightElement name="CollectionName">
                <div
                  block="ProductCard"
                  elem="Information-CollectionNameSkuWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="Information-CollectionName"
                    >
                    { productColor }
                    </div>
                    <span block="ProductCard" elem="Information-Sku">
                        { sku }
                    </span>
                </div>
                <div
                  block="ProductCard"
                  elem="Information-ProductNameString"
                >
                    { brand }
                </div>
                <div
                  block="ProductCard"
                  elem="Information-ProductNameString-Collection"
                >
                    { collection_name }
                </div>
            </EqualHeightElement>
        );
    }

    renderAccessoryInformation() {
        const {
            isEqualHeight,
            product: {
                sku,
                name,
                dynamicAttributes: {
                    brand = '',
                    flooring_type
                } = {}
            }
        } = this.props;

        // Replacing escaped quote in SS
        if (!isEqualHeight) {
            return (
                <>
                <div
                  block="ProductCard"
                  elem="Information-NameSkuWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="Information-Name"
                    >
                        { name }
                    </div>
                    <span block="ProductCard" elem="Information-Sku">
                        { sku }
                    </span>
                </div>
                <div
                  block="ProductCard"
                  elem="Information-FlooringType"
                >
                    { flooring_type }
                </div>
                <div
                  block="ProductCard"
                  elem="Information-Brand"
                >
                    { brand }
                </div>
                </>
            );
        }

        // Replacing escaped quote in SS
        return (
            <EqualHeightElement name="CollectionName">
                <div
                  block="ProductCard"
                  elem="Information-NameSkuWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="Information-Name"
                    >
                        { name }
                    </div>
                    <span block="ProductCard" elem="Information-Sku">
                        { sku }
                    </span>
                </div>
                <div
                  block="ProductCard"
                  elem="Information-FlooringType"
                >
                    { flooring_type }
                </div>
                <div
                  block="ProductCard"
                  elem="Information-Brand"
                >
                    { brand }
                </div>
            </EqualHeightElement>
        );
    }

    renderRugInformation() {
        const {
            isEqualHeight,
            product: {
                sku,
                dynamicAttributes: {
                    brand = '',
                    collection_name = ''
                } = {},
                rugAttributes: {
                    rug_sizes = [],
                    rug_color = ''
                } = {}
            }
        } = this.props;

        const plankWidth = rug_sizes ? ` | ${ rug_sizes.toString() }` : '';
        const productNameString = (brand + plankWidth)?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&');

        if (!isEqualHeight) {
            return (
                <>
                    <div
                      block="ProductCard"
                      elem="Information-CollectionName"
                    >
                        { rug_color }
                    </div>
                    <div
                      block="ProductCard"
                      elem="Information-ProductNameString"
                    >
                        { productNameString }
                    </div>
                    <div
                      block="ProductCard"
                      elem="Information-ProductNameString-Collection"
                    >
                        { collection_name }
                    </div>
                    <span block="ProductCard" elem="Information-Sku">
                        { sku }
                    </span>
                </>
            );
        }

        // Either pass prop value or '' because prop value can be null, which will throw an error
        return (
            <EqualHeightElement name="CollectionName">
                <div
                  block="ProductCard"
                  elem="Information-CollectionNameSkuWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="Information-CollectionName"
                    >
                        { rug_color }
                    </div>
                    <span block="ProductCard" elem="Information-Sku">
                        { sku }
                    </span>
                </div>
                <div
                  block="ProductCard"
                  elem="Information-ProductNameString"
                >
                    { productNameString }
                </div>
                <div
                  block="ProductCard"
                  elem="Information-ProductNameString-Collection"
                >
                    { collection_name }
                </div>
            </EqualHeightElement>
        );
    }

    renderFullProductInformation() {
        const {
            isEqualHeight,
            product: {
                sku,
                attribute_set_id,
                dynamicAttributes,
                dynamicAttributes: {
                    brand = '',
                    collection_name = '',
                    collection_color = '',
                    plank_width = ''
                } = {},
                type_id
            },
            linkType
        } = this.props;

        const plankWidth = plank_width ? ` | ${ plank_width }"` : '';
        const productNameString = (brand + plankWidth)?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&');

        if (dynamicAttributes === undefined || dynamicAttributes === {}) {
            return null;
        }

        if (linkType === CROSS_SELL) {
            return null;
        }

        if (type_id === CONFIGURABLE) {
            return this.renderConfigurableProductInformation();
        }

        if (attribute_set_id === ACCESSORIES_ATTRIBUTE_SET_ID) {
            return this.renderAccessoryInformation();
        }

        if (attribute_set_id === RUG_ATTRIBUTE_ID) {
            return this.renderRugInformation();
        }

        if (!isEqualHeight) {
            return (
                <>
                    <div
                      block="ProductCard"
                      elem="Information-CollectionName"
                    >
                        { collection_color }
                    </div>
                    <div
                      block="ProductCard"
                      elem="Information-ProductNameString"
                    >
                        { productNameString }
                    </div>
                    <div
                      block="ProductCard"
                      elem="Information-ProductNameString-Collection"
                    >
                        { collection_name }
                    </div>
                    <span block="ProductCard" elem="Information-Sku">
                        { sku }
                    </span>
                </>
            );
        }

        // Either pass prop value or '' because prop value can be null, which will throw an error
        return (
            <EqualHeightElement name="CollectionName">
                <div
                  block="ProductCard"
                  elem="Information-CollectionNameSkuWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="Information-CollectionName"
                    >
                        { collection_color }
                    </div>
                    <span block="ProductCard" elem="Information-Sku">
                        { sku }
                    </span>
                </div>
                <div
                  block="ProductCard"
                  elem="Information-ProductNameString"
                >
                    { productNameString }
                </div>
                <div
                  block="ProductCard"
                  elem="Information-ProductNameString-Collection"
                >
                    { collection_name }
                </div>
            </EqualHeightElement>
        );
    }

    renderBrandValue() {
        const {
            isEqualHeight,
            siblingsHaveBrands,
            setSiblingsHaveBrands,
            product: {
                dynamicAttributes
            }
        } = this.props;

        if (dynamicAttributes === undefined) {
            return null;
        }

        const {
            collection_color,
            brand
        } = dynamicAttributes;

        if (!brand || !collection_color) {
            return null;
        }

        if (!siblingsHaveBrands) {
            setSiblingsHaveBrands();
        }

        if (!isEqualHeight) {
            // Either pass prop value or '' because prop value can be null, which will throw an error
            return (
                <div
                  block="ProductCard"
                  elem="BrandValueWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="BrandAttributeValueColor"
                    >
                        { `${ collection_color } | ${ brand }` || '' }
                    </div>
                </div>
            );
        }

        // Either pass prop value or '' because prop value can be null, which will throw an error
        return (
            <EqualHeightElement name="BrandAttributeValueColor">
                <div
                  block="ProductCard"
                  elem="BrandValueWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="BrandAttributeValueColor"
                    >
                        { `${ collection_color } | ${ brand }` || '' }
                    </div>
                </div>
            </EqualHeightElement>
        );
    }

    renderAdditionalProductDetails() {
        const {
            isMolding,
            product: {
                dynamicAttributes: {
                    accessory_length
                } = {}
            } = {}
        } = this.props;

        if (!isMolding) {
            return null;
        }

        const length = accessory_length ? `Size: ${ accessory_length }` : '';

        return (
                <div block="ProductCard" elem="Length">
                    <p>{ length }</p>
                </div>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const {
            linkTo, product: { url }, linkType, isCartPopup
        } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        if (linkType === CROSS_SELL) {
            return (
                <a
                  block="ProductCard"
                  elem="Link"
                  href={ linkTo.pathname }
                  mix={ mix }
                  mods={ { isCrossSell: true, isCartPopup } }
                >
                    { children }
                </a>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    renderImageVisualOption(sku, label, value, i) {
        const {
            onError,
            product: {
                sku: currentProductsSku
            }
        } = this.props;

        // If domain is passed from BE don't prepend anything
        return (
          <img
            mods={ { isSelected: currentProductsSku === sku } }
            key={ i }
            block="ProductCard"
            elem="Image"
            src={ value?.search('http://') !== -1 || value?.search('https://') !== -1 ? value : `/media/catalog/product${value}` }
            alt={ label }
            // eslint-disable-next-line react/jsx-no-bind
            onError={ (e) => onError(e) }
          />
        );
    }

    renderProductCardWishlistButton() {
        const {
            product,
            hideWishlistButton,
            isWishlistEnabled,
            groupedProductQuantity
        } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return (
            <ProductWishlistButton
              product={ product }
              mix={ { block: 'ProductCard', elem: 'WishListButton' } }
              groupedProductQuantity={ groupedProductQuantity || {} }
              type="PLPWishlist"
            />
        );
    }

    renderVisualConfigurableOptions() {
        const {
            isDiscontinued,
            isConfigurableSwatchPopUp,
            siblingsHaveConfigurableOptions,
            setSiblingsHaveConfigurableOptions,
            popUpProducts,
            toggleProductPopUp,
            product: {
                sku: currentSku,
                url,
                swatch: {
                    url: swatchImageUrl
                }
            },
            isPreview
        } = this.props;

        if (isPreview) {
            return <TextPlaceholder />;
        }

        // If there was no configurable option with color, don't render popup
        if (isConfigurableSwatchPopUp && popUpProducts[0]?.attribute === '') {
            return (
                <Link
                  to={ url }
                >
                    { __('Click here for all options') }
                </Link>
            );
        }

        if (!validOptionTypes.includes(popUpProducts[0]?.type) && !popUpProducts?.length) {
            return null;
        }

        if (!siblingsHaveConfigurableOptions) {
            setSiblingsHaveConfigurableOptions();
        }

        let [{ swatchImageUrl: currentImage = '' } = {}] = popUpProducts.filter(
            (swatch) => swatch.sku === currentSku
        );

        if (isDiscontinued) {
            currentImage = swatchImageUrl;
        }

        if (isConfigurableSwatchPopUp) {
            currentImage = popUpProducts[0].swatchImageUrl;
        }

        const htmlFor = `SwatchPopupTriggerCheckbox${ currentSku }`;

        return (
                <div
                  block="ProductCard"
                  elem="ConfigurableOptionsLabel"
                  onClick={ toggleProductPopUp }
                  onKeyDown={ toggleProductPopUp }
                  role="button"
                  tabIndex={ 0 }
                >
                    <label
                      block="ProductActions"
                      elem="SwatchPopupTriggerLabel"
                      htmlFor={ htmlFor }
                    >
                        <ImageWebp
                          mix={ { block: 'ProductCard', elem: 'CurrentImage' } }
                          src={ currentImage }
                          ratio="custom"
                          style={ { objectFit: 'cover' } }
                          alt={ `${ currentSku } swatch` }
                        />
                        <span
                          block="ProductCard"
                          elem="ViewPopupLink"
                        >
                            { `View ${ popUpProducts?.length } ${ isConfigurableSwatchPopUp ? 'sizes' : 'colors' }` }
                        </span>
                    </label>
                </div>
        );
    }

    renderUpsellWishlistButton() {
        const { product, hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return (
            <ProductWishlistButton
              type={ PRODUCT_ACTIONS_WISHLIST_BUTTON }
              product={ product }
              mix={ { block: 'ProductCard', elem: 'WishListButton' } }
              groupedProductQuantity={ {} }
            />
        );
    }

    renderProductPrice() {
        const {
            isDiscontinued,
            customerGroupName,
            isSearchSpringSearch,
            wrapperProduct: {
                final_price
            } = {},
            productOrVariant: {
                price_range: {
                    maximum_price: {
                        final_price: {
                            value: finalPrice
                        } = {}
                    } = {}
                } = {}
            },
            product: {
                attribute_set_id = '',
                coverage_area_type,
                price_range,
                type_id,
                dynamicAttributes,
                dynamicAttributes: {
                    coverage_area_type: related_coverage_area_type,
                    request_quote_enabled,
                    require_form_to_add_to_cart
                } = {},
                rugAttributes: {
                    regular_price = ''
                } = {},
                attributes: {
                    show_price = null
                } = {},
                price_range: {
                    minimum_price: {
                        regular_price: {
                            value: related_regular_price = ''
                        } = {}
                    } = {}
                } = {}
            },
            popUpProducts,
            configurableMinPrice,
            configurableMaxPrice,
            isConfigurableSwatchPopUp,
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
            linkType,
            isCartPopup
        } = this.props;
        const isNoMapRestrictions = customerGroupName === NO_MAP_RESTRICTIONS || customerGroupName === WHOLESALE;
        const isShowPrice = show_price && show_price === 'Yes';
        const isRequestQuote = request_quote_enabled === 'Yes';
        const isRelated = linkType === RELATED;
        const isUpsell = linkType === UPSELL;

        if (dynamicAttributes === undefined) {
            return null;
        }

        if (isDiscontinued) {
            return (
                <div block="ProductCard" elem="DiscontinuedBadgeWrapper">
                    { this.renderProductTypePriceBadge() }
                </div>
            );
        }

        const { special_price, unit_price } = dynamicAttributes;

        switch (type_id) {
        case CONFIGURABLE:
            if (isConfigurableProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        case BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        if (linkType === CROSS_SELL) {
            return (
                <>
                    { this.renderTierPrice() }
                    <ProductPrice
                      price={ price_range }
                      mix={ {
                          block: 'ProductCard',
                          elem: 'CrossSellPrice',
                          mods: { isCartPopup, isHidden: (show_price && show_price === 'No' && !isNoMapRestrictions) }
                      } }
                    />
                </>
            );
        }

        if (isConfigurableSwatchPopUp && popUpProducts?.length) {
            const price = `$${ configurableMinPrice }-$${ configurableMaxPrice }`;
            const requireForm = require_form_to_add_to_cart === 'Yes';
            const msrp = requireForm ? `Msrp: ${price}` : price;

            return (
                <div block="ProductCard" elem="PriceWrapper" mods={ { isLineThrough: requireForm } }>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-Price"
                      mods={ {
                          isLineThrough: requireForm,
                          isHidden: (show_price && show_price === 'No' && !isNoMapRestrictions)
                      } }
                    >
                        { msrp }
                    </div>
                    { (isRequestQuote || requireForm) && this.renderProductTypePriceBadge() }
                </div>
            );
        }

        // Having replace as in some cases unit price comes with $ sign in others it comes without
        // We have to ensure there is no string character to convert to Number
        const displayedUnitPrice = (isRequestQuote && !isShowPrice)
            ? ''
            : unit_price?.replace('$', '') || '';

        const isSpecialPrice = !!special_price && displayedUnitPrice;
        const msrp = isSpecialPrice
            ? `Msrp: $${Number(displayedUnitPrice).toFixed(2)}`
            : `$${Number(displayedUnitPrice).toFixed(2)} `;
        const rugMsrp = special_price
            ? `Msrp: $${Number(regular_price || related_regular_price).toFixed(2)}`
            : `$${Number(regular_price || related_regular_price).toFixed(2)}`;
        const msrpToDisplay = (isRequestQuote && !isShowPrice) ? '' : msrp;
        const isLineThrough = !!special_price;
        const isNormalPrice = !special_price;
        const isAccessory = attribute_set_id.toString() === ACCESSORIES_ATTRIBUTE_SET_ID;
        const priceToApply = isSearchSpringSearch ? final_price : finalPrice;
        const isHidden = show_price
            // checking show_price for SS and regular attributes
            && (show_price === 'No' || show_price?.attribute_value === '0')
            && !isNoMapRestrictions;

        // Customization for rug products
        if (attribute_set_id.toString() === RUG_ATTRIBUTE_ID) {
            return (
                <div block="ProductCard" elem="PriceWrapper" mods={ { isLineThrough, isRelated, isUpsell } }>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-RugPrice"
                      mods={ { isLineThrough, isNormalPrice, isHidden } }
                    >
                        { rugMsrp }
                    </div>
                    { (!!special_price || isRequestQuote) && this.renderProductTypePriceBadge() }
                </div>
            );
        }

        if (attribute_set_id === ACCESSORIES_ATTRIBUTE_SET_ID) {
            const coverage = `/ ${ coverage_area_type || related_coverage_area_type }`;

            return (
                <div block="ProductCard" elem="PriceWrapper" mods={ { isLineThrough, isAccessoriesPrice: true } }>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-BoxPrice"
                      mods={ {
                          isBoxPriceHidden: !isSpecialPrice,
                          isAccessory
                      } }
                    >
                        { priceToApply }
                        <span block="ProductCard" elem="PriceWrapper-Units">
                            { coverage }
                        </span>
                    </div>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-Price"
                      mods={ {
                          isLineThrough,
                          isNormalPrice,
                          isAccessory,
                          isHidden: (isHidden || isNormalPrice)
                      } }
                    >
                        { msrpToDisplay }
                        <span block="ProductCard" elem="PriceWrapper-Units">
                            { coverage }
                        </span>
                    </div>
                </div>
            );
        }

        return (
                <div block="ProductCard" elem="PriceWrapper" mods={ { isLineThrough, isRelated, isUpsell } }>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-Price"
                      mods={ {
                          isLineThrough,
                          isNormalPrice,
                          isAccessory,
                          isHidden
                      } }
                    >
                        { msrpToDisplay }
                        { !isSpecialPrice && (!isRequestQuote || isShowPrice) && (
                            <span block="ProductCard" elem="PriceWrapper-Units">
                                / sq. ft.
                            </span>
                        ) }
                    </div>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-BoxPrice"
                      mods={ {
                          isBoxPriceHidden: (
                              !!isSpecialPrice
                              || (isRequestQuote && !isShowPrice)
                              || isHidden),
                          isAccessory
                      } }
                    >
                        { `$${ priceToApply } / box` }
                    </div>
                    { (!!isSpecialPrice || isRequestQuote) && this.renderProductTypePriceBadge() }
                </div>
        );
    }

    renderProductTypePriceBadge() {
        const {
            customerGroupName,
            isDiscontinued,
            product: {
                attribute_set_id,
                dynamicAttributes
            }
        } = this.props;

        if (dynamicAttributes === undefined) {
            return null;
        }

        if (isDiscontinued) {
            return (
                <div block="ProductCard" elem="PriceBadgeWrapper">
                    <p
                      mix={ {
                          block: 'ProductCard',
                          elem: 'DiscontinuedBadge'
                      } }
                    >
                        { __('Discontinued') }
                    </p>
                </div>
            );
        }

        const {
            price_label,
            special_price,
            request_quote_enabled,
            require_form_to_add_to_cart
        } = dynamicAttributes;
        const flooringAndAccessoriesLabel = customerGroupName === VERIFIED_CUSTOMERS
            ? __('Price In Cart') : (price_label || '');

        if (attribute_set_id === RUG_ATTRIBUTE_ID) {
            let label = (require_form_to_add_to_cart === 'Yes' || !!special_price) ? __('Price In Cart') : '';
            label = request_quote_enabled === 'Yes' ? __('Price in Quote') : label;
            label = customerGroupName === VERIFIED_CUSTOMERS ? __('Price In Cart') : label;

            return (
                <div block="ProductCard" elem="PriceBadgeWrapper">
                    <p
                      mix={ {
                          block: 'ProductCard',
                          elem: 'PriceBadge'
                      } }
                    >
                        { label }
                    </p>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="PriceBadgeWrapper">
                <p
                  mix={ {
                      block: 'ProductCard',
                      elem: 'PriceBadge'
                  } }
                >
                    { flooringAndAccessoriesLabel }
                </p>
            </div>
        );
    }

    render() {
        const {
            isSelected,
            children,
            mix,
            mods: sourceMods,
            linkType,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout,
            isDiscontinued
        } = this.props;

        const mods = {
            ...sourceMods,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        const isBlock = linkType !== CROSS_SELL && linkType !== RELATED && linkType !== UPSELL;

        if (linkType === RELATED && isDiscontinued) {
            return null;
        }

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ { ...mods, block: isBlock } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { ...mods, selected: isSelected, block: isBlock } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
